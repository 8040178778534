import React from 'react';
import { graphql, Link } from 'gatsby';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import logger from 'utils/logger';
import Fade from 'react-reveal/Fade';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import Layout from 'components/Layout';
import SEO from 'components/seo';

import { Container, Row } from 'components/GridSystem';
import {
  BusinessFunction,
  SectionLabel,
  SectionTitle
} from 'components/Text';
import { CapabilityCard } from 'components/Cards';
import {
  BgImg,
  Button,
  Img
} from 'components/Elements';

import {
  Capabilities,
  CapabilityHighlight,
  Expertise,
  Footer,
  IntroService
} from 'components/Sections';
import { CallToActionPreFooter } from 'components/CallToActions';

const SectionFocus = styled.section`
  ${tw`
    py-6
    bg-gray-100
  `};
`;
const SectionFocusContent = styled.p`
  ${tw`

  `};
`;
const SectionCapabilities = styled.section`
  ${tw`
    pb-6
  `};
`;
/* 
const SectionApproach = styled.section`
  ${tw`
    relative
    flex
    py-3
    xl:min-h-screen-80
    bg-gray-100
    text-black
    items-stretch
    content-center
    flex-wrap
  `};
  h1 {
    ${tw`
      font-serif
      font-normal
      xl:text-hero-xl
      tracking-tight
      leading-tight
    `}; 
  }
  > div {
    ${tw`
      flex-2
    `};
  }
`;
 */
const easing = 500;
const transition = css`
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
  &:hover, &:focus {
    transition: all ${easing / 2}ms cubic-bezier(.1,.55,.4,1);
  }
`;

const SectionApproach = styled(Link)`
  ${tw`
    mt-3
    block
    bg-gray-500
    mb-0
  `};
  & * {
    ${tw`
      text-white
    `};
  }
  & span {
    ${tw`
      text-gray-300
    `};
  }
  ${transition};
  &:hover,
  &:focus {
    ${tw`
      bg-brand-primary-700
      text-white
    `};
    & span {
      ${tw`
        text-white
      `};
    }
    & span > span {
      &:before {
        content: "";
        ${tw`
          w-1
          mx-0.5
        `};
      }
    }
  }
`;
const ColImg = styled.div`
  ${tw`
    bg-gray-100
    md:w-1/2
  `};
`;
const ColText = styled.div`
  ${tw`
    py-6
    md:w-1/2
  `};
  p {
    ${tw`
      text-lg
      leading-normal
      font-light
      tracking-tight
    `};
  }
  ${(props) => {
    const { reverse } = props;
    return [
      reverse ? tw`xl:px-1/12` : tw`xl:px-1/12`,
    ];
  }};
`;
const CTAMore = styled.span`
  ${tw`
    font-semibold
    mx-0.25
    relative
    tracking-wider
    uppercase
    text-md
  `};
  & span {
    ${tw`
      inline-block
    `};
    &:before {
      content: "";
      ${transition};
      ${tw`
        relative
        mx-1
        inline-block
        bg-gray-500
        mb-4px
        w-8
        h-2px
      `};
    }
  }
`;
const Title = styled.h1`
  ${tw`
    font-serif
    xl:text-hero-lg
    xl:leading-tighter
    xl:tracking-tighter
    xl:ml--0.5
  `};
  ${(props) => {
    const { light } = props;
    return [
      light && tw`font-normal`,
    ];
  }};
  &:after {
    content: "";
    ${tw`
      block
      bg-brand-primary
      my-3
      w-6
      h-4px
      xl:ml-0.5
    `};
  }
`;
 const SectionIntro = styled.div`
  ${tw`
    py-1.5
    flex
    items-stretch
    content-center
    flex-wrap
  `};
  img {
    ${tw`
      max-w-full
    `};
  }
  h1 {
    ${tw`
      xl:text-hero-lg
    `};
  }
  p {
  ${tw`
    xl:text-lg
  `};
  ${(props) => {
    const { col } = props;
    return [
      col === 2 && tw`
        xl:w-1/2
        xl:inline-block
        xl:pr-1
      `
    ];
  }};
  }
`;

const SectionIntroContent = styled.div`
  ${tw`
    xl:text-xl
    font-light
    leading-normal
    pb-2.5
    xl:w-3/5
  `};
  ${(props) => {
    const { light } = props;
    return [
      light && tw`text-white`
    ];
  }};
`;
const StyledImg = styled(Img)`
  ${tw`
    w-full
  `};
  img {light
    ${tw`
      mb-0
      pb-0
    `};
  }
`;
const SectionIntroCol = styled.div`
  ${tw`
    w-full
    self-start
    relative
    xl:max-w-11/24
    pr-1
  `};
  :nth-child(even) {
    ${tw`
      xl:min-h-screen-80
    `};
  }
  :nth-child(odd) {
    ${tw`
      self-center
      xl:max-w-1/2
      xl:mr-1/24
      xl:pr-1/12
    `};
  }
  h1 {
    ${tw`
      xl:text-hero-lg
      xl:leading-tighter
    `};
  ${(props) => {
    const { light } = props;
    return [
      light && tw`text-white`
    ];
  }};
  }
  p {
  ${tw`
    xl:text-lg
  ${(props) => {
    const { light } = props;
    return [
      light && tw`text-white`
    ];
  }};
  `};
  }
`;
const FocusArea = styled.section`
  ${tw`
    xl:w-1/3
    pr-4
    pt-2
  `};
  h1 {
    ${tw`
      xl:text-2xl
      uppercase
      tracking-wider
    `};
  }
  hr {
    ${tw`
      bg-brand-primary-400
      w-3
      ml-0
      mb-2.5
    `};
  }
  p {
    ${tw`
      xl:text-lg
      text-gray-800
    `};
  }
  img {
    ${tw`
      max-w-6
    `};
    filter: invert(30%);
  }
`;
const AreasLabel = styled.h2`
  ${tw`
    font-serif
    xl:text-hero-lg
    xl:leading-tighter
    xl:tracking-tighter
    xl:ml--0.5
  `};
  &:after {
    content: "";
    ${tw`
      block
      bg-gray-300
      my-3
      w-full
      h-2px
    `};
  }
`;
const AreasIntro = styled.div`
  ${tw`
    pt-6
    pb-4
    border-0
    border-t-2
    border-solid
    border-gray-200
    mx-3
    sm:mx-1/8
    md:mx-1/12
    xl:mx-1/24
    xl:px-1/24
  `};
  h1 {
    ${tw`
      font-serif
      tracking-tight
    `};
  }
  p {
    ${tw`
      font-light
      tracking-tight
      leading-fit
      text-gray-400
      xl:w-1/2
      xl:text-2xl
    `};
  }
`;

class Page extends React.Component {
  render () {
    logger.template(__filename, '>', 'render()');
    const {
      data,
      location
    } = this.props;
    logger.gql(data);
    const siteTitle = data.site.siteMetadata.title;

    const {
      title,
      fields
    } = data.contentfulPage;
    const { blocks } = fields;

    const capabilities = data.allContentfulCapability.edges;

    const focusAreas = [
      {
        node: {
          id: `d7e1243d-ec43-44f8-8614-83c6937957b1`,
          title: `Strategy`,
          description: `We provide strategic, creative and technology expertise to grow brands & businesses`
        }
      },
      {
        node: {
          id: `d7e1243d-ec43-44f8-8614-83c693795711`,
          title: `Design & Build`,
          description: `We provide strategic, creative and technology expertise to grow brands & businesses`
        }
      },
      {
        node: {
          id: `d7e1243d-ec43-44f8-8614-83c693795741`,
          title: `Activation`,
          description: `We provide strategic, creative and technology expertise to grow brands & businesses`
        }
      }
    ];

    const animationDuration = 600;
    logger.templateEnd();
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title={title} />
        <Fade>
          <article>
            <IntroService
              bgColor={`#fff`}
              label={title}
              intro={blocks[0].titleRich && blocks[0].titleRich.childMdx.rawBody}
              content={blocks[0].content && blocks[0].content.childMdx.body}
            />
            {/*
            <SectionFocus>
              <Container>
                <Row>
                  <Fade left duration={animationDuration} distance={`2%`} delay={animationDuration}>
                    <SectionLabel label={`Focus Areas`} />
                  </Fade>
                </Row>
                <Row>
                  { focusAreas && (
                    focusAreas.length > 0 && focusAreas.map((edge, i) => {
                      const { node } = edge;
                      return (
                        <FocusArea key={node.id}>
                          <Fade left duration={animationDuration} distance={`2%`} delay={i * 200}>
                            <img src="https://cdn1.iconfinder.com/data/icons/artificial-intelligence-3/128/Network-Model-Sharing-Connection-Structure-Framework-512.png" alt="" />
                            <h1>
                              {node.title}
                            </h1>
                            <hr />
                            <SectionFocusContent>
                              {node.description}
                            </SectionFocusContent>
                          </Fade>
                        </FocusArea>
                      );
                    })
                  )}
                </Row>
              </Container>
            </SectionFocus>
            */}
            <SectionCapabilities>
              <Container fluid>
                <Row>
                  <AreasIntro>
                    <h1>{blocks[2].titleRich && blocks[2].titleRich.childMdx.rawBody}</h1>
                    <div>
                      <MDXRenderer>
                        {blocks[2].content && blocks[2].content.childMdx.body}
                      </MDXRenderer>
                    </div>
                  </AreasIntro>
                </Row>
              </Container>
              <Container>
                <Row>
                  { capabilities && (
                    capabilities.length > 0 && capabilities.map((edge, i) => {
                      const { node } = edge;
                      const props = {
                        title: node.title,
                        animationDuration,
                        animationDelay: (i > 2 ? i - 2.5 : i) * 200,
                        src: `https://images.ctfassets.net/yz9sknn7lxni/13HVR43AvaD4AOLY5Ep7lO/13f64d50150a688c0aec1050763665f1/placeholder.png`
                      };
                      return (
                        <CapabilityCard key={node.id} {...node} {...props} />
                      );
                    })
                  )}
                </Row>
              </Container>
            </SectionCapabilities>
            {/* 
            <SectionApproach to={`/approach`}>
              <Container fluid>
                <Row>
                  <ColImg>
                  </ColImg>
                  <ColText>
                    <SectionLabel label={`Approach`} />
                    <Title light>{`It's not what to do but how can it impact your business.`}</Title>
                    {blocks && (
                      blocks[0].content && (
                        blocks[0].content.childMdx && (
                          <Fade right duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                            <MDXProvider
                              components={{
                                h1: props => <Heading {...props} />,
                                Lead: props => <Lead {...props} />
                              }}
                            >
                              <MDXRenderer>
                                {blocks[0].content.childMdx.body}
                              </MDXRenderer>
                            </MDXProvider>
                          </Fade>
                        )
                      )
                    )}
                    <CTAMore>
                      Learn More
                    <span>About our approach</span>
                    </CTAMore>
                  </ColText>
                </Row>
              </Container>
            </SectionApproach>
            */}
            {/*
            <SectionApproach>
              { blocks[2].featuredImage && (
                <BgImg {...blocks[2].featuredImage.cover} mask={0.6} />
              )}
              <Container>
                <Row>
                  <SectionIntroCol light>
                    <Fade top duration={animationDuration} distance={`10%`} delay={animationDuration / 3}>
                      <h1>
                        {blocks[3].titleRich && blocks[3].titleRich.childMdx.rawBody}
                      </h1>
                    </Fade>
                    <Fade bottom duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                      <SectionIntroContent light>
                        {blocks[2].content && blocks[2].content.childMdx.rawBody}
                      </SectionIntroContent>
                    </Fade>
                    <Fade left duration={animationDuration} distance={`10%`} delay={animationDuration}>
                      <Button
                        label={`Discover More`}
                        color={`brand-primary`}
                        size={`md`}
                        outlineX
                        uppercase
                        lighten
                        navigateTo={`/approach`}
                      />
                    </Fade>
                  </SectionIntroCol>
                  <SectionIntroCol>
                    {blocks[3].featuredImage && (
                      <StyledImg
                        title={blocks[3].featuredImage.title}
                        alt={blocks[3].featuredImage.description}
                        fluid={blocks[3].featuredImage.cover.fluid}
                        style={{
                          position: `contain`
                        }}
                        objectFit={`contain`}
                        objectPosition={`50% 50%`}
                        fadeIn
                      />
                    )}
                  </SectionIntroCol>
                </Row>
              </Container>
            </SectionApproach>
            <Container>
              <Row>
                <h1>Case Studies Masonry</h1>
              </Row>
            </Container>
            */}
          </article>
          <CallToActionPreFooter
            bgColor={`gray-700`}
            title={`Have a challenge you’d like us to explore?`}
            callToAction={`Get in touch!`}
            uri={``}
            to={`/contact`}
            utm={{
              campaign: `t`
            }}
          />
        </Fade>
      </Layout>
    );
  }
}

export default Page;

export const pageQuery = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage ( slug: { eq: $slug } ){
      ...Page
    }
    allContentfulCapability ( sort: { fields: sort, order: ASC} ){
      edges{
        node{
          ...Capability
        }
      }
    }
  }
`;
